<template>
    <div class="what-is-memod">
        <h1 class="h0">
            About us
        </h1>
        <div class="video-preview" @click="$modal.show('video-intro-modal')">
            <div class="play-button">
                <div class="row no-gutters justify-content-center align-items-center">
                    <div class="col-auto">
                        <div class="play-icon">
                            <img src="@assets/img/founders-landing/hero/play-icon.svg" alt="Play">
                        </div>
                    </div>
                    <div class="col">
                        <span>Watch video</span>
                    </div>
                </div>
            </div>
        </div>
        <p>Memo'd is a note-sharing app where you can find inspirations and ideas shared by our community of creators</p>
    </div>
</template>

<script>
export default {
    name: "WhatIsMemod"
}
</script>

<style lang="scss" scoped>
.what-is-memod {
    background-image: url("~@assets/img/what-is-memod-bg.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 110%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;

    @media(max-width: $md) {
        margin-bottom: 40px;
    }

    h1 {
        margin-bottom: 65px;

        @media(max-width: $xl) {
            text-align: center;
        }
    }

    .video-preview {
        position: relative;
        border-radius: 18px;
        overflow: hidden;
        width: 995px;
        height: 564px;
        cursor: pointer;
        background-image: url("~@assets/img/founders-landing/video-preview.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;

        @media(max-width: $lg) {
            width: 665px;
            height: 375px;
        }

        @media(max-width: $md) {
            width: 546px;
            height: 320px;
        }

        @media(max-width: $sm) {
            width: 100%;
            height: 400px;
            background-position: left;
            margin-bottom: 30px;
        }

        .play-button {
            width: 165px;
            height: 64px;
            background-color: rgba(0,0,0,0.4);
            border-radius: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .play-icon {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid rgba(235,235,245,0.6);
                border-radius: 100%;
                margin-right: 10px;

                img {
                    margin-left: 4px;
                }
            }
        }
    }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;

        @media(max-width: $md) {
            font-size: 18px;
            line-height: 27px;
        }
    }
}
</style>
